import { Injectable } from "@angular/core";
import { IsActiveMatchOptions } from "@angular/router";
import { NavigationItem } from "../types/navigation.types";
import { UserService } from "./user/user.service";
import { AuthService } from "./auth.service";


@Injectable({ providedIn: 'root' })
export class NavigationService {

    constructor(private authService: AuthService){}

    private _navigationItems: NavigationItem[] = [
      {
        id: 'dashboard',
        title: 'Dashboard',
        link: '/dashboard',
        icon: 'mat_outline:speed',
        
      },
      {
        id: 'trade',
        title: 'Smart Trade Data',
        link: '/trade',
        icon: 'mat_outline:trending_up'
      },
      {
        id: 'settings',
        title: 'Settings',
        link: '/settings',
        icon: 'mat_outline:settings',
        //hidden: (item) => this.authService.canCreateCompanyUser(),
        expanded: true,
        children: [
          {
            id: 'account',
            title: 'Account',
            link: '/settings/account',
            children: [
              {
                id: 'profile',
                title: 'Profile',
                link: '/settings/account/profile'
              },
              {
                id: 'team',
                title: 'Team',
                hidden: (item) => this.authService.canCreateCompanyUser(),
                link: '/settings/account/team'
              },
              {
                id: 'change-password',
                title: 'Change Password',
                link: '/settings/account/change-password'
              }
            ]
          },
          {
            id: 'favorites',
            title: 'Favorites',
            link: '/settings/favorites',
            children: [
              {
                id: 'fav-filter',
                title: 'Filter',
                link: '/settings/favorites/filter'
              }
            ]
          }
        ]
      }
    ];


     get navigationItems(): NavigationItem[] {
        return this._navigationItems;
     }

    get subsetMatchOptions(): IsActiveMatchOptions { 
        return {
            paths       : 'subset',
            fragment    : 'ignored',
            matrixParams: 'ignored',
            queryParams : 'subset',
      };
    }
    get exactMatchOptions(): IsActiveMatchOptions {
      return {
          paths       : 'exact',
          fragment    : 'ignored',
          matrixParams: 'ignored',
          queryParams : 'subset',
      }
    };
    
    toggleNavigationItem(item: NavigationItem): void {
        let index = this._navigationItems.findIndex((navItem) => navItem === item)
        if (index !== -1) {
          console.log('toggleNavigationItem', this._navigationItems[index].expanded);
            this._navigationItems[index].expanded = !this._navigationItems[index].expanded;
        }
    }

    getSubNavigation(item:NavigationItem): NavigationItem[]|undefined {
      return this._navigationItems
      .findIndex((navItem) => navItem === item) !== -1 ? item.children : [];
    }
    
}